import React, { useState } from "react";
import {
  Dropdown,
  emailToHSL,
  Icon,
  Marble,
  Option,
} from "@hoylu/client-common";
import { useDispatch, useSelector } from "react-redux";
import SectionLayout from "./layouts/SectionLayout";
import { Analytics } from "./analytics/Analytics";
import { ReportType } from "./analytics/ReportFactory";
import { FilterProvider } from "./analytics/FilterProvider";
import { CopyIcon } from "./modals/sharing/icons/CopyIcon";
import Styles from "./ProjectSection.module.css";
import Scrollbar from "@hoylu/client-common/dist/esm/assets/css.modules/scrollbar.module.css";
import DefaultDropdownStyles from "@hoylu/client-common/dist/esm/assets/css.modules/default.dropdown.module.css";
import { projectDeepLink } from "../utils/url.selectors";
import { openManagementPortal } from "../utils/management.portal/openManagementPortal";
import {
  getAllProjectCollaborators,
  isAdminOfSelectedProject,
} from "../state/workspaces/projects.selector";
import { managementPortalProjectUrl } from "../utils/management.portal/management.portal.url.selectors";
import { fetchMasterData, reset } from "../state/analytics/analytics.actions";
import { selectedProject } from "../state/workspaces/workspaces.selector";
import { activateDashboardOption } from "../state/workspaces/workspaces.actions";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import { useI18n } from "../utils/hooks/use.i18n";
import { featureFlags } from "../state/config/config.selector";
import {
  isMasterDataRequestFailed,
  selectHasEmptyScope,
} from "../state/analytics/analytics.selector";

const ProjectSection: React.FC = () => {
  const dispatch = useDispatch();
  const t = useI18n("SECTIONS.PROJECT.");
  const deepLink = useSelector(projectDeepLink);
  const manageProjectUrl = useSelector(managementPortalProjectUrl);
  const collaborators = useSelector(getAllProjectCollaborators);
  const isAdmin = useSelector(isAdminOfSelectedProject);
  const project = useSelector(selectedProject);
  const flags = useSelector(featureFlags);

  const availableReports: Option<ReportType>[] = [
    { label: "Task by Status", value: "status" },
    { label: "PPC", value: "ppc" },
    { label: "Variance Reasons", value: "reason" },
  ];

  const [report, setReport] = useState<Option<ReportType>>(availableReports[0]);

  const editIconStyle = {
    color: "var(--theme-text)",
    fontSize: "var(--global--size-x-large)",
  };

  const showCollaborators = collaborators.length > 0;
  const showProjectSettings = isAdmin && !!manageProjectUrl;

  const hasEmptyScope = useSelector(selectHasEmptyScope);
  const isMissingMasterData = useSelector(isMasterDataRequestFailed);
  const showNexusReporting =
    flags.enableNexusReporting && !hasEmptyScope && !isMissingMasterData;

  return (
    <SectionLayout>
      <div
        className={`${Styles.header} ${Scrollbar.normal} ${Scrollbar.horizontal}`}
        tabIndex={0}
        aria-label={t("COLLABORATORS")}
      >
        <div className={Styles.title}>
          <span>{t("DASHBOARD")}</span>
          {showNexusReporting && (
            <Dropdown
              onChange={(val) => setReport(val[0])}
              values={[report]}
              options={availableReports}
              useMultiSelect={false}
              allowReset={false}
              title={t("REPORT_TYPE")}
              classes={[DefaultDropdownStyles.dropdown, Styles.dropdown]}
            />
          )}
        </div>
        {showCollaborators && (
          <div
            className={`${Styles.collaborators} ${Scrollbar.normal} ${Scrollbar.horizontal}`}
            tabIndex={0}
            aria-label={t("COLLABORATORS")}
          >
            {collaborators.map((email) => (
              <CollaboratorMarble key={email} collaboratorEmail={email} />
            ))}
          </div>
        )}
        <div title={t("COPY_LINK")} tabIndex={0}>
          <CopyIcon
            icon={"hoylu-ui-icons-link"}
            style={editIconStyle}
            text={deepLink}
          />
        </div>
        {showProjectSettings && (
          <>
            <div title={t("PROJECT_SETTINGS")} tabIndex={0}>
              <Icon
                icon={"hoylu-ui-icons-setting"}
                style={editIconStyle}
                onClick={() => openManagementPortal(manageProjectUrl)}
              />
            </div>
            {showNexusReporting && (
              <div title={t("DELETE_SCOPE")} tabIndex={1}>
                <Icon
                  icon={"hoylu-ui-icons-trash"}
                  style={editIconStyle}
                  onClick={() =>
                    dispatch(
                      activateDashboardOption({
                        optionType: DashboardOption.DROP_SCOPE,
                      })
                    )
                  }
                />
              </div>
            )}
          </>
        )}
        {showNexusReporting && (
          <div title={t("RELOAD")} tabIndex={2}>
            <Icon
              icon={"hoylu-ui-icons-refresh"}
              style={editIconStyle}
              onClick={() => {
                dispatch(reset());
                project?.id && dispatch(fetchMasterData.request(project.id));
              }}
            />
          </div>
        )}
      </div>
      {flags.enableNexusReporting && (
        <FilterProvider>
          {!hasEmptyScope && !isMissingMasterData && (
            <Analytics type={report.value} />
          )}
        </FilterProvider>
      )}
    </SectionLayout>
  );
};

type CollaboratorMarbleProps = {
  collaboratorEmail: string;
};

const CollaboratorMarble = ({ collaboratorEmail }: CollaboratorMarbleProps) => {
  const initial = collaboratorEmail[0];
  const projectMarbleStyles = {
    width: "3rem",
    minWidth: "3rem",
    height: "3rem",
    fontSize: "var(--global--size-h1)",
    backgroundColor: emailToHSL(collaboratorEmail),
    boxShadow: "var(--global--shadow--lg)",
  };

  return (
    <Marble
      key={`marble-${collaboratorEmail}`}
      title={collaboratorEmail}
      style={projectMarbleStyles}
    >
      {initial}
    </Marble>
  );
};

export default React.memo(ProjectSection);
