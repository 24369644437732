import React from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { cancelDashboardOption } from "../../state/workspaces/workspaces.actions";
import { getSelectedWorkspaceDetails } from "../../state/workspaces/workspaces.selector";
import { Localized } from "../../strings";
import { Modal } from "./Modal";
import { Button, DraggableWindowHeader } from "@hoylu/client-common";
import { deleteTemplate } from "../../state/templates/templates.actions";
import Styles from "./DeleteTemplateDialog.module.css";

const mapStateToProps = (state: RootState) => ({
  selectedWorkspaceDetails: getSelectedWorkspaceDetails(state),
});

const mapDispatchToProps = {
  cancelDashboardOption,
};

type RemoveTemplateDialogProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const DeleteTemplateDialog: React.FC<RemoveTemplateDialogProps> = ({
  cancelDashboardOption,
  selectedWorkspaceDetails,
}) => {
  const dispatch = useDispatch();
  const strings = Localized.object("DELETE_TEMPLATE_DIALOG");

  const removeTemplate = () => {
    if (!selectedWorkspaceDetails?.templateId) {
      return;
    }

    dispatch(deleteTemplate.request(selectedWorkspaceDetails));
    cancelDashboardOption();
  };

  return (
    <Modal
      handleOutsideClick={cancelDashboardOption}
      allowContentToOverflow={true}
    >
      <div
        className={Styles.deleteTemplateDialog}
        data-test-id="linked-master-ws-dialog"
      >
        <DraggableWindowHeader
          title={strings.TITLE}
          onClose={cancelDashboardOption}
        />
        <div className={Styles.subtitle}>
          <span>{strings.SUBTITLE}</span>
        </div>
        <div className={Styles.buttons}>
          <Button
            onClick={cancelDashboardOption}
            type="secondary"
            dataTestId={strings.CANCEL}
          >
            {strings.CANCEL}
          </Button>
          <Button
            type={"primary"}
            onClick={removeTemplate}
            dataTestId={"delete-template"}
          >
            {strings.CONTINUE}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteTemplateDialog);
