import type { UseAssignWorkspaceToProjectResult } from "./types";
import type { WorkspaceDetails } from "../../state/workspaces/types";
import { AssignToProjectStrategy } from "./assign.to.project.strategy";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProjects,
  getAssignableProjects,
} from "../../state/workspaces/projects.selector";
import { useEffect, useMemo, useState } from "react";
import { ProjectNotSelected } from "./defaults";

export const useAssignWorkspaceToProject = (
  workspaceDetails: WorkspaceDetails
): UseAssignWorkspaceToProjectResult => {
  const dispatch = useDispatch();
  const projects = useSelector(getAllProjects);
  const assignableProjects = useSelector(getAssignableProjects);
  const assignToProjectStrategy = new AssignToProjectStrategy(
    projects ?? [],
    assignableProjects,
    dispatch
  );

  const [currentProjects, setCurrentProjects] = useState(() =>
    assignToProjectStrategy.initialSelectedOption(workspaceDetails.containerId)
  );

  const projectOptions = assignToProjectStrategy.getProjectOptions();

  const projectHasChanged = useMemo(() => {
    const currentProjectId = currentProjects[0]?.value.id;

    const isWorkspaceWithoutProject = !workspaceDetails?.containerId;
    const isRemoveProjectOption = currentProjectId === ProjectNotSelected;

    const isRemovingFromWorkspaceWithoutProject =
      isWorkspaceWithoutProject && isRemoveProjectOption;

    return (
      !isRemovingFromWorkspaceWithoutProject &&
      currentProjectId !== workspaceDetails?.containerId
    );
  }, [currentProjects, workspaceDetails?.containerId]);

  const saveProject = () => {
    const projectOption = currentProjects[0];
    if (!projectHasChanged || !projectOption || !workspaceDetails?.workspaceId)
      return;
    assignToProjectStrategy.assignToProject(
      workspaceDetails.workspaceId,
      projectOption.value
    );
  };

  useEffect(() => {
    saveProject();
  }, [projectHasChanged]);

  return {
    currentProjects,
    setCurrentProjects,
    projectOptions,
    saveProject,
    projectHasChanged,
  };
};
