import { createReducer } from "typesafe-actions";
import { resetState } from "../root.action";
import * as actions from "./analytics.actions";
import {
  NexusTaskDto,
  MasterData,
  PpcResult,
  PpcTeamResult,
  PpcWeekResult,
  ReportResult,
  TeamReasonReportResult,
  TeamStatusReportResult,
  WeekReasonReportResult,
  WeekStatusReportResult,
} from "@hoylu/nexus-service";

export interface AnalyticsState {
  masterData: AnalyticData<MasterData>;
  taskStatus?: AnalyticData<ReportResult[]>;
  taskStatusByWeek?: AnalyticData<WeekStatusReportResult[]>;
  taskStatusByTeam?: AnalyticData<TeamStatusReportResult[]>;
  taskPPC?: AnalyticData<PpcResult[]>;
  taskPPCByWeek?: AnalyticData<PpcWeekResult[]>;
  taskPPCByTeam?: AnalyticData<PpcTeamResult[]>;
  taskReason?: AnalyticData<ReportResult[]>;
  taskReasonByWeek?: AnalyticData<WeekReasonReportResult[]>;
  taskReasonByTeam?: AnalyticData<TeamReasonReportResult[]>;
  tasks?: PagedAnalyticData<NexusTaskDto[]>;
}

export type AnalyticData<TData> = {
  isLoading: boolean;
  data?: TData;
  fetchFailed?: boolean;
};

export type PagedAnalyticData<TData> = {
  total?: number;
  next?: string;
} & AnalyticData<TData>;

export type TaskData = {
  title: string;
  entryId: string;
  status: string;
  reason?: string;
  isCompletedOnTime: boolean;
  isPlanned: boolean;
  swimlane: string;
  team: string;
  weekDate: string;
  workspaceId: string;
};

export const defaultState = (): AnalyticsState => ({
  masterData: {
    isLoading: false,
    fetchFailed: false,
    data: {
      team: [],
      reason: [],
      status: [],
      label: [],
      assignee: [],
      workspace: [],
      from: "",
      to: "",
    },
  },
});

export default createReducer<AnalyticsState>(defaultState())
  .handleAction(actions.reset, () => defaultState())
  .handleAction(resetState, () => defaultState())
  .handleAction(actions.fetchTaskStatus.request, (state) => ({
    ...state,
    taskStatus: { ...state.taskStatus, isLoading: true },
  }))
  .handleAction(actions.fetchTaskStatus.success, (state, action) => ({
    ...state,
    taskStatus: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskStatus.failure, (state) => ({
    ...state,
    taskStatus: { ...state.taskStatus, isLoading: false, fetchFailed: true },
  }))
  .handleAction(actions.fetchTaskStatusByTeam.request, (state) => ({
    ...state,
    taskStatusByTeam: { ...state.taskStatusByTeam, isLoading: true },
  }))
  .handleAction(actions.fetchTaskStatusByTeam.success, (state, action) => ({
    ...state,
    taskStatusByTeam: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskStatusByTeam.failure, (state) => ({
    ...state,
    taskStatusByTeam: {
      ...state.taskStatusByTeam,
      isLoading: false,
      fetchFailed: true,
    },
  }))
  .handleAction(actions.fetchTaskStatusByWeek.request, (state) => ({
    ...state,
    taskStatusByWeek: { ...state.taskStatusByWeek, isLoading: true },
  }))
  .handleAction(actions.fetchTaskStatusByWeek.success, (state, action) => ({
    ...state,
    taskStatusByWeek: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskStatusByWeek.failure, (state) => ({
    ...state,
    taskStatusByWeek: {
      ...state.taskStatusByWeek,
      isLoading: false,
      fetchFailed: true,
    },
  }))
  .handleAction(actions.fetchTaskPPC.request, (state) => ({
    ...state,
    taskPPC: { ...state.taskPPC, isLoading: true },
  }))
  .handleAction(actions.fetchTaskPPC.success, (state, action) => ({
    ...state,
    taskPPC: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskPPC.failure, (state) => ({
    ...state,
    taskPPC: { ...state.taskPPC, isLoading: false, fetchFailed: true },
  }))
  .handleAction(actions.fetchTaskPPCByTeam.request, (state) => ({
    ...state,
    taskPPCByTeam: { ...state.taskPPCByTeam, isLoading: true },
  }))
  .handleAction(actions.fetchTaskPPCByTeam.success, (state, action) => ({
    ...state,
    taskPPCByTeam: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskPPCByTeam.failure, (state) => ({
    ...state,
    taskPPCByTeam: {
      isLoading: false,
      fetchFailed: true,
    },
  }))
  .handleAction(actions.fetchTaskPPCByWeek.request, (state) => ({
    ...state,
    taskPPCByWeek: { ...state.taskPPCByWeek, isLoading: true },
  }))
  .handleAction(actions.fetchTaskPPCByWeek.success, (state, action) => ({
    ...state,
    taskPPCByWeek: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskPPCByWeek.failure, (state) => ({
    ...state,
    taskPPCByWeek: {
      ...state.taskPPCByWeek,
      isLoading: false,
      fetchFailed: true,
    },
  }))

  .handleAction(actions.fetchTaskReason.request, (state) => ({
    ...state,
    taskReason: { ...state.taskReason, isLoading: true },
  }))
  .handleAction(actions.fetchTaskReason.success, (state, action) => ({
    ...state,
    taskReason: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskReason.failure, (state) => ({
    ...state,
    taskReason: { ...state.taskReason, isLoading: false, fetchFailed: true },
  }))

  .handleAction(actions.fetchTaskReasonByWeek.request, (state) => ({
    ...state,
    taskReasonByWeek: { ...state.taskReasonByWeek, isLoading: true },
  }))
  .handleAction(actions.fetchTaskReasonByWeek.success, (state, action) => ({
    ...state,
    taskReasonByWeek: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskReasonByWeek.failure, (state) => ({
    ...state,
    taskReasonByWeek: {
      ...state.taskReasonByWeek,
      isLoading: false,
      fetchFailed: true,
    },
  }))

  .handleAction(actions.fetchTaskReasonByTeam.request, (state) => ({
    ...state,
    taskReasonByTeam: { ...state.taskReasonByTeam, isLoading: true },
  }))
  .handleAction(actions.fetchTaskReasonByTeam.success, (state, action) => ({
    ...state,
    taskReasonByTeam: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTaskReasonByTeam.failure, (state) => ({
    ...state,
    taskReasonByTeam: {
      isLoading: false,
      fetchFailed: true,
    },
  }))
  .handleAction(actions.fetchMasterData.request, (state) => ({
    ...state,
    taskFilters: { ...state.masterData, isLoading: true },
  }))
  .handleAction(actions.fetchMasterData.success, (state, action) => ({
    ...state,
    masterData: {
      isLoading: false,
      data: action.payload,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchMasterData.failure, (state) => ({
    ...state,
    masterData: { ...state.masterData, isLoading: false, fetchFailed: true },
  }))
  .handleAction(actions.fetchTasks.request, (state) => ({
    ...state,
    tasks: { data: [], isLoading: true, next: undefined },
  }))
  .handleAction(actions.fetchTasks.success, (state, action) => ({
    ...state,
    tasks: {
      data: action.payload.data,
      isLoading: false,
      next: action.payload.next,
      total: action.payload.total,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchTasks.failure, (state) => ({
    ...state,
    tasks: {
      data: [],
      isLoading: false,
      fetchFailed: true,
      next: undefined,
      total: undefined,
    },
  }))
  .handleAction(actions.fetchMoreTasks.request, (state) => ({
    ...state,
    tasks: { ...state.tasks, isLoading: true },
  }))
  .handleAction(actions.fetchMoreTasks.success, (state, action) => ({
    ...state,
    tasks: {
      data: [...(state.tasks?.data ?? []), ...action.payload.data],
      isLoading: false,
      next: action.payload.next,
      total: state.tasks?.total,
      fetchFailed: false,
    },
  }))
  .handleAction(actions.fetchMoreTasks.failure, (state) => ({
    ...state,
    tasks: {
      data: [],
      isLoading: false,
      fetchFailed: true,
      next: undefined,
      total: undefined,
    },
  }));
