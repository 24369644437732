import { useSelector } from "react-redux";
import { getAllWorkspaces } from "../state/workspaces/workspaces.selector";
import React, { useMemo } from "react";
import { LiveWorkspace } from "./live.workspace";

export const LiveWorkspacesContainer = () => {
  const workspaces = useSelector(getAllWorkspaces);
  const liveWorkspaces = useMemo(() => {
    return workspaces.filter((workspace) => workspace.isLive);
  }, [workspaces]);

  return (
    <div>
      {liveWorkspaces.map((workspace) => (
        <LiveWorkspace workspace={workspace} key={workspace.workspaceId} />
      ))}
    </div>
  );
};
