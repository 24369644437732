import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import Header from "./Header/Header";
import { WorkspacesLayout } from "./layouts/WorkspacesLayout";
import {
  getAllWorkspaces,
  getSelectedProjectWorkspaces,
  isCreditLimitReached,
  isHiddenSectionVisible,
  isWorkspaceFullscreen,
} from "../state/workspaces/workspaces.selector";
import {
  activateDashboardOption,
  editWorkspace,
  queueEditWorkspace,
} from "../state/workspaces/workspaces.actions";
import {
  Project,
  WorkspaceDetails,
  WorkspaceLabels,
} from "../state/workspaces/types";
import { CreateWorkspaceSection } from "./CreateWorkspaceSection";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import ErrorFetchingWorkspace from "./ErrorFetchingWorkspace";
import { LoadMoreWorkspacesButton } from "./LoadMoreWorkspacesButton";
import { isAdminOfSelectedProject } from "../state/workspaces/projects.selector";
import { FrameContexts } from "@microsoft/teams-js";
import TeamsSidePanel from "./TeamsSidePanel";
import ProjectSection from "./ProjectSection";
import { Localized } from "../strings";
import SectionLayout from "./layouts/SectionLayout";
import OrgBanner from "./OrgBanner";
import { dropInvalidExternalLink } from "../utils/url.helpers";
import { enableFavoritesWorkspaceSection } from "../state/config/config.selector";
import { ProjectStatuses } from "../state/workspaces/workspaces.reducer";
import WorkspaceCard from "./cards/WorkspaceCard/WorkspaceCard";
import Styles from "./WorkspaceContent.module.css";
import { openManagementPortal } from "../utils/management.portal/openManagementPortal";
import { managementPortalBillingUrl } from "../utils/management.portal/management.portal.url.selectors";
import { UserSettingsContext } from "@hoylu/user-settings";
import { allLocalizations } from "../strings";
import { useI18n } from "../utils/hooks/use.i18n";
import { LiveWorkspacesContainer } from "./live.workspaces.container";

type WorkspacesResult = {
  favoritesWorkspaces: WorkspaceDetails[];
  hiddenWorkspaces: WorkspaceDetails[];
  baseWorkspaces: WorkspaceDetails[];
};

export const matchName = (searchTerm: string, workspace: WorkspaceDetails) => {
  const lowerCaseSearchTerm = searchTerm.trim().toLocaleLowerCase();
  if (workspace.workspaceName !== undefined) {
    if (
      workspace.workspaceName.toLocaleLowerCase().includes(lowerCaseSearchTerm)
    ) {
      return true;
    }
  }
  if (workspace.labels) {
    if (
      workspace.labels.find(
        (l) =>
          l.name.trim().toLocaleLowerCase().indexOf(lowerCaseSearchTerm) !== -1
      )
    ) {
      return true;
    }
  }

  return false;
};

export const matchLabels = (
  labels: WorkspaceLabels,
  workspace: WorkspaceDetails
) => {
  if (labels.length === 0) {
    return true;
  }
  if (!workspace.labels || workspace.labels.length === 0) {
    return false;
  }
  const lCaseWorkspaceLabels = workspace.labels.map((workspaceLabel) =>
    workspaceLabel.name.trim().toLocaleLowerCase()
  );
  const lCaseSearchLabels = labels.map((label) =>
    label.name.trim().toLocaleLowerCase()
  );
  return lCaseWorkspaceLabels.some((r) => lCaseSearchLabels.indexOf(r) >= 0);
};

const matchSelectedProject = (
  selectedProject: Project | null,
  workspace: WorkspaceDetails
): boolean => {
  if (!selectedProject) return true;
  return workspace.containerId === selectedProject.id;
};

const keepLiveWorkspaces = (
  workspaces: WorkspaceDetails[],
  selectedProjectWorkspaces: WorkspaceDetails[]
) => {
  // Note: when a project is selected, all the live workspace cards needs to be kept alive, otherwise data might get lost (e.g.: when disconnected)
  //       also remove duplicates for the live ones
  //       all others can be removed, to reduce load on the browser
  const liveWorkspaces = workspaces.filter((w) => w.isLive);
  const liveWorkspaceIds = liveWorkspaces.map((w) => w.workspaceId);

  return [
    ...liveWorkspaces,
    ...selectedProjectWorkspaces.filter(
      (w) => !liveWorkspaceIds.includes(w.workspaceId)
    ), // filter live workspaces from selected project,
  ];
};

const getWorkspaceLists = (
  workspaces: WorkspaceDetails[],
  selectedProjectWorkspaces: WorkspaceDetails[],
  selectedProject: Project | null
) => {
  // Note: When a project is selected, this list still contains all the live workspaces (in front) to not re-load them or close their iframe.
  //       The live workspaces that are not part of the selected project get hidden
  const processedWorkspaces = selectedProject
    ? keepLiveWorkspaces(workspaces, selectedProjectWorkspaces)
    : workspaces;

  // Group workspaces for UI sections
  const aggregateWorkspacesCollection: WorkspacesResult = processedWorkspaces.reduce(
    (acc: WorkspacesResult, ws: WorkspaceDetails) => {
      if (ws.isFavorite) {
        acc.favoritesWorkspaces.push(ws);
      } else if (ws.isHidden) {
        acc.hiddenWorkspaces.push(ws);
      } else {
        acc.baseWorkspaces.push(ws);
      }
      return acc;
    },
    { favoritesWorkspaces: [], hiddenWorkspaces: [], baseWorkspaces: [] }
  );

  return aggregateWorkspacesCollection;
};

export const WorkspaceContent: React.FC<WorkspaceContentProps> = ({
  isWorkspaceFullscreen,
  isLoggedIn,
  bannerLink,
  orgBanner,
  showFavoritesSection,
  workspaces,
  fetchFailure,
  selectedProjectWorkspaces,
  openWorkspace,
  openAssetPanel,
  config,
  selectedProject,
  isAdministratorOfSelectedProject,
  searchTerm = "",
  showWorkspaceTemplates,
  isOverCreditLimit,
  isHiddenSectionVisible,
}) => {
  const [filterLabels, setFilterLabels] = useState<WorkspaceLabels>([]);
  const showOrgBanner = useMemo(() => isLoggedIn && orgBanner, [
    isLoggedIn,
    orgBanner,
  ]);
  const settingsContext = useContext(UserSettingsContext);
  const billingUrl = useSelector(managementPortalBillingUrl);
  const t = useI18n("SECTIONS.");

  useEffect(() => {
    const currentLanguage = settingsContext.userSettings?.currentLanguage;
    // "en" is set as the default on a workspace init
    if (currentLanguage && currentLanguage !== "en") {
      Localized.pushLocale(currentLanguage, allLocalizations[currentLanguage]);
    }
  }, [settingsContext.userSettings?.currentLanguage]);

  const showCreateWorkspaceSection =
    !selectedProject ||
    (isAdministratorOfSelectedProject &&
      selectedProject.status !== ProjectStatuses.Inactive);

  const {
    baseWorkspaces,
    favoritesWorkspaces,
    hiddenWorkspaces,
  } = getWorkspaceLists(
    workspaces,
    selectedProjectWorkspaces ?? [],
    selectedProject
  );

  const onEditWorkspaceClicked = (workspaceId: string) => {
    openWorkspace(workspaceId, !!selectedProject);
  };

  if (config.teamsFrame === FrameContexts.sidePanel) {
    return <TeamsSidePanel />;
  }

  return (
    <>
      {!isWorkspaceFullscreen && (
        <Header
          selectedLabels={filterLabels}
          handleLabelsChange={setFilterLabels}
        />
      )}
      <div className={Styles.workspaceContent}>
        {showOrgBanner && (
          <OrgBanner bannerLink={bannerLink} orgBanner={orgBanner} />
        )}
        {isOverCreditLimit && !selectedProject && (
          <div className={Styles.creditOverLimit}>
            <h1>{Localized.string("CREDIT_LIMIT_WARNING_BANNER.TITLE")}</h1>
            &nbsp;
            <h1
              onClick={() => openManagementPortal(billingUrl)}
              className={Styles.billingLink}
            >
              {Localized.string("CREDIT_LIMIT_WARNING_BANNER.BUTTON")} &gt;
            </h1>
          </div>
        )}
        {selectedProject && <ProjectSection />}
        {showCreateWorkspaceSection && (
          <div className={Styles.restrictedView}>
            <SectionLayout
              ariaLabel={Localized.string("SECTIONS.START_FROM_TEMPLATE.LABEL")}
              className={Styles.createWorkspaceSectionLayout}
            >
              <CreateWorkspaceSection />
            </SectionLayout>
          </div>
        )}
        {fetchFailure && <ErrorFetchingWorkspace />}
        {showFavoritesSection && favoritesWorkspaces.length > 0 && (
          <SectionLayout title={t("FAVORITES")}>
            <WorkspacesLayout>
              {favoritesWorkspaces.map((w: WorkspaceDetails) => (
                <WorkspaceCard
                  hide={
                    !w.isFullscreen
                      ? (searchTerm && !matchName(searchTerm, w)) ||
                        !matchLabels(filterLabels, w) ||
                        !matchSelectedProject(selectedProject, w)
                      : false
                  }
                  order={w.order}
                  key={`${w.workspaceId}_favorite`}
                  workspaceDetails={w}
                  editWorkspaceAction={onEditWorkspaceClicked}
                  isFavorite={true}
                />
              ))}
            </WorkspacesLayout>
          </SectionLayout>
        )}
        {isHiddenSectionVisible && hiddenWorkspaces.length > 0 && (
          <SectionLayout title={t("HIDDEN")}>
            <WorkspacesLayout>
              {hiddenWorkspaces.map((w: WorkspaceDetails) => (
                <WorkspaceCard
                  hide={
                    !w.isFullscreen
                      ? (searchTerm && !matchName(searchTerm, w)) ||
                        !matchLabels(filterLabels, w) ||
                        !matchSelectedProject(selectedProject, w)
                      : false
                  }
                  order={w.order}
                  key={`${w.workspaceId}`}
                  workspaceDetails={w}
                  editWorkspaceAction={onEditWorkspaceClicked}
                />
              ))}
            </WorkspacesLayout>
          </SectionLayout>
        )}
        <SectionLayout title={t("WORKSPACES_LIST")}>
          <WorkspacesLayout>
            {baseWorkspaces.map((w: WorkspaceDetails) => (
              <WorkspaceCard
                hide={
                  !w.isFullscreen
                    ? (searchTerm && !matchName(searchTerm, w)) ||
                      !matchLabels(filterLabels, w) ||
                      !matchSelectedProject(selectedProject, w)
                    : false
                }
                order={w.order}
                key={w.workspaceId}
                workspaceDetails={w}
                editWorkspaceAction={onEditWorkspaceClicked}
              />
            ))}
          </WorkspacesLayout>
        </SectionLayout>
        <LoadMoreWorkspacesButton />
      </div>
      <LiveWorkspacesContainer />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  bannerLink: dropInvalidExternalLink(state.context.user.orgBannerLink),
  isWorkspaceFullscreen: isWorkspaceFullscreen(state),
  isLoggedIn: state.context.user.isLoggedIn === true,
  orgBanner: state.context.user.orgBannerUrl,
  showFavoritesSection: enableFavoritesWorkspaceSection(state),
  workspaces: getAllWorkspaces(state),
  fetchFailure: state.context.workspaces.fetchFailure,
  selectedProjectWorkspaces: getSelectedProjectWorkspaces(state),
  config: state.context.config,
  selectedProject: state.context.workspaces.selectedProject,
  isAdministratorOfSelectedProject: isAdminOfSelectedProject(state),
  searchTerm: state.context.workspaces.searchTerm,
  showWorkspaceTemplates: state.context.config.featureFlags.workspaceTemplates,
  isOverCreditLimit: isCreditLimitReached(state),
  isHiddenSectionVisible: isHiddenSectionVisible(state),
});

const mapDispatchToProps = {
  createWorkspace: (useCaseName: string) =>
    activateDashboardOption({
      optionType: DashboardOption.CREATE,
      useCaseName: useCaseName,
    }),
  openWorkspace: (workspaceId: string, isProjectWorkspace: boolean) =>
    isProjectWorkspace
      ? queueEditWorkspace({ workspaceId: workspaceId })
      : editWorkspace({ workspaceId, lastAccess: new Date() }),
  openAssetPanel: () =>
    activateDashboardOption({
      optionType: DashboardOption.ASSET_PANEL,
    }),
};

type WorkspaceContentProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceContent);
