import React, { useEffect, useMemo, useState, useRef } from "react";
import { ShareSection } from "./ShareSection";
import { ShareSectionHeader } from "./ShareSectionHeader";
import {
  Button,
  OpenDropdownRef,
  TextInput,
  useValidatedState,
} from "@hoylu/client-common";
import SimpleWorkspaceCard from "../../cards/SimpleWorkspaceCard";
import { useDispatch, useSelector } from "react-redux";
import * as workspaceActions from "../../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../../state/workspaces/DashboardOption";
import { RootState } from "typesafe-actions";
import { InfoSection } from "./ShareType";
import { editThumbnail } from "../../../state/workspaces/workspaces.actions";
import { getUserOrgId } from "../../../state/user/user.selector";
import Styles from "./WorkspaceInfoSection.module.css";
import { isRequiredValidation } from "../../../utils/is.required.validation";
import { isWithinCharLimitValidation } from "../../../utils/is.within.char.limit.validation";
import { RemoveButton } from "../modalElements/RemoveButton";
import { WorkspaceDetails } from "../../../state/workspaces/types";
import { useAssignWorkspaceToProject } from "../../project/use.assign.workspace.to.project";
import { ProjectDropdownWithRemoveButton } from "../../project/ProjectDropdownWithRemoveButton";
import { useI18n } from "../../../utils/hooks/use.i18n";

export type WorkspaceInfoSectionProps = {
  initiallyOpen: boolean;
  isOrgAdmin: boolean;
  handleChangeSectionType: (section: InfoSection) => void;
  workspaceDetails: WorkspaceDetails;
  openDropdownRef: React.Ref<OpenDropdownRef>;
  shouldSelectTitle: boolean;
};

export const WorkspaceInfoSection = ({
  initiallyOpen,
  isOrgAdmin,
  handleChangeSectionType,
  workspaceDetails,
  openDropdownRef,
  shouldSelectTitle,
}: WorkspaceInfoSectionProps) => {
  const dispatch = useDispatch();
  const t = useI18n("SHARE_WORKSPACE_DIALOG.");
  const [isNameEdited, setIsNameEdited] = useState(false);
  const [wasNameSend, setWasNameSend] = useState(false);
  const titleInputRef = useRef<HTMLInputElement>(null);

  const {
    currentProjects,
    setCurrentProjects,
    projectOptions,
  } = useAssignWorkspaceToProject(workspaceDetails);

  const isWorkspaceAdmin = !!workspaceDetails.isAdmin;

  const userOrgId = useSelector(getUserOrgId);

  const showTemplateCreationUIFlag = useSelector(
    (state: RootState) =>
      state.context.config.featureFlags.showTemplateCreationUI
  );
  const showOrgTemplateCreationUIFlag = useSelector(
    (state: RootState) => state.context.config.featureFlags.orgTemplates
  );
  const showPublishAsTemplate =
    showTemplateCreationUIFlag || (isOrgAdmin && showOrgTemplateCreationUIFlag);

  const isWorkspaceOwnedByUserOrg = workspaceDetails.orgId === userOrgId;

  const [isOpenSection, setIsOpenSection] = useState(initiallyOpen);
  const [workspaceName, setWorkspaceName, nameError] = useValidatedState<
    string | undefined
  >(workspaceDetails.workspaceName, [
    isRequiredValidation,
    isWithinCharLimitValidation,
  ]);

  const nameHasChanged = useMemo(
    () => workspaceDetails.workspaceName !== workspaceName,
    [workspaceDetails.workspaceName, workspaceName]
  );

  const saveWorkspaceName = () => {
    if (!workspaceName || nameError || !nameHasChanged || !isNameEdited) return;

    dispatch(
      workspaceActions.updateWorkspaceName.request({
        workspaceId: workspaceDetails.workspaceId,
        name: workspaceName,
      })
    );
    setIsNameEdited(false);
    setWasNameSend(true);
  };
  console.log(workspaceDetails.workspaceName, workspaceName);
  const showRemoveWorkspacePrompt = () =>
    dispatch(
      workspaceActions.activateDashboardOption({
        optionType: DashboardOption.REMOVE,
        workspaceId: workspaceDetails.workspaceId,
      })
    );

  const toggleOnHeaderClick = () => {
    setIsOpenSection((prevState) => !prevState);
  };

  useEffect(() => {
    if (!nameHasChanged) return;

    const handler = setTimeout(() => {
      saveWorkspaceName();
    }, 500);

    return () => clearTimeout(handler);
  }, [workspaceName, nameHasChanged]);

  const titleInputLabel = useMemo(() => {
    let label = t("WORKSPACE_TITLE");

    if (nameHasChanged && isNameEdited) {
      label += ` - ${t("SAVING")}`;
    }

    if (!nameHasChanged && wasNameSend) {
      label += ` - ${t("SAVED")}`;
    }

    return label;
  }, [nameHasChanged, wasNameSend]);

  const handleTitleInputBlur = () => {
    setTimeout(() => {
      setWasNameSend(false);
    }, 3000);
  };

  const handleKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event?.key === "Enter") {
      saveWorkspaceName();
    }
  };

  useEffect(() => {
    if (shouldSelectTitle) {
      titleInputRef.current?.select();
    }
  }, []);

  const handleNameChange = (value: string | undefined) => {
    setWorkspaceName(value);
    setIsNameEdited(true);
  };

  return (
    <ShareSection testId="workspace-info">
      <ShareSectionHeader
        sectionIcon={"hoylu-ui-icons-blank"}
        sectionTitle={t("WORKSPACE_INFO")}
        isSectionVisible={isOpenSection}
        onHeaderClick={toggleOnHeaderClick}
        alwaysShowIcons={true}
      />
      {isOpenSection && !!workspaceDetails && (
        <div className={Styles.workspaceInfoSection}>
          <div className={Styles.workspaceInfoEditContainer}>
            <SimpleWorkspaceCard workspaceDetails={workspaceDetails}>
              {isWorkspaceAdmin ? (
                <div
                  className={Styles.workspaceCard}
                  onClick={() => dispatch(editThumbnail({ flag: true }))}
                >
                  <span className={"hoylu-ui-icons-edit"} />
                  <span>{t("CHANGE_THUMBNAIL")}</span>
                </div>
              ) : (
                <span
                  className={Styles.truncate}
                  title={workspaceDetails.workspaceName}
                >
                  {workspaceDetails.workspaceName}
                </span>
              )}
            </SimpleWorkspaceCard>
            <div className={Styles.infoContainer}>
              <div style={{ display: "flex" }}>
                <TextInput
                  label={titleInputLabel}
                  value={workspaceName}
                  onChange={handleNameChange}
                  error={nameError}
                  style={{ flex: 1 }}
                  disabled={!isWorkspaceAdmin}
                  onKeyUp={handleKeyUp}
                  isFocus={shouldSelectTitle}
                  parentRef={titleInputRef}
                  onBlur={handleTitleInputBlur}
                />
              </div>
              <ProjectDropdownWithRemoveButton
                workspaceDetails={workspaceDetails}
                currentProjects={currentProjects}
                projectOptions={projectOptions}
                setCurrentProjects={setCurrentProjects}
                isEditing={true}
                openDropdownRef={openDropdownRef}
              />
            </div>
          </div>
          <div className={Styles.footer}>
            <RemoveButton
              onClick={showRemoveWorkspacePrompt}
              text={t("REMOVE_WORKSPACE")}
            />
            {isWorkspaceAdmin && (
              <div>
                {showPublishAsTemplate && (
                  <Button
                    type={"secondary"}
                    toolTip={
                      !isWorkspaceOwnedByUserOrg
                        ? t("PUBLISH_AS_TEMPLATE_NOT_ALLOWED")
                        : t("PUBLISH_AS_TEMPLATE")
                    }
                    onClick={() =>
                      handleChangeSectionType(InfoSection.TEMPLATE)
                    }
                    disabled={!isWorkspaceOwnedByUserOrg}
                  >
                    {t("PUBLISH_AS_TEMPLATE")}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </ShareSection>
  );
};
