import React, { CSSProperties } from "react";
import { WorkspaceDetails } from "../../../state/workspaces/types";
import { useSelector } from "react-redux";
import { enableFavoritesWorkspaceSection } from "../../../state/config/config.selector";
import { Icon } from "@hoylu/client-common";
import WorkspacePreviewLabels from "./WorkspacePreviewLabels";
import { WorkspaceWeight } from "../WorkspaceWeight";
import { Localized } from "../../../strings";
import { isWorkspaceLimited } from "../../../state/workspaces/workspaces.selector";
import { RootState } from "typesafe-actions";
import Styles from "./WorkspaceImagePreview.module.css";
import { selectAnalyticsWorkspaces } from "../../../state/analytics/analytics.selector";

type WorkspaceImagePreviewProps = {
  imgSrc: string;
  showWorkspaceWeight: boolean;
  setWorkspaceFavorite: () => void;
  canEditLabels: boolean;
  showEditLabelPrompt: () => void;
  workspaceDetails: WorkspaceDetails;
};

const WorkspaceImagePreview = ({
  imgSrc,
  setWorkspaceFavorite,
  canEditLabels,
  showEditLabelPrompt,
  showWorkspaceWeight,
  workspaceDetails,
}: WorkspaceImagePreviewProps): JSX.Element => {
  const isWorkspaceFavorite = !!workspaceDetails.isFavorite;
  const isLimited = useSelector((state: RootState) =>
    isWorkspaceLimited(state, workspaceDetails.workspaceId)
  );
  const showFavoritesStar = useSelector(enableFavoritesWorkspaceSection);
  const nexusWorkspaces = useSelector(selectAnalyticsWorkspaces);
  const showNexusLabelIcon = nexusWorkspaces.includes(
    workspaceDetails.workspaceId
  );

  const cardIconStyles: CSSProperties = {
    position: "absolute",
    top: "0",
    fontSize: "var(--global--size-large)",
    // We have to reset the Icon's default paddings
    padding: "0",
  };

  return (
    <>
      <img
        className={Styles.workspaceImage}
        src={imgSrc}
        alt={Localized.string("WORKSPACE_CARD.WORKSPACE_IMG_ALT")}
      />
      {showFavoritesStar && (
        <Icon
          icon={
            isWorkspaceFavorite
              ? "hoylu-ui-icons-star-filled"
              : "hoylu-ui-icons-star"
          }
          style={{
            ...cardIconStyles,
            color: isWorkspaceFavorite ? "#fff" : "",
            right: "var(--global--spacing--default)",
          }}
          onClick={setWorkspaceFavorite}
        />
      )}
      <WorkspacePreviewLabels
        canEditLabels={canEditLabels}
        showEditLabelPrompt={showEditLabelPrompt}
        workspaceDetails={workspaceDetails}
      />
      {showNexusLabelIcon && (
        <Icon
          icon={"hoylu-ui-icons-nexus"}
          style={{
            ...cardIconStyles,
            left: "var(--global--size-large)",
          }}
        />
      )}
      {showWorkspaceWeight && (
        <WorkspaceWeight
          workspaceWeight={workspaceDetails.weight}
          inWarningMode={isLimited}
        />
      )}
    </>
  );
};

export default WorkspaceImagePreview;
