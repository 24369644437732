import { createAsyncAction } from "typesafe-actions";
import {
  FetchOrganizationTemplatesResponse,
  FetchTemplateCategoriesResponse,
  FetchTemplatesResponse,
} from "../../services/templates/types";
import {
  PublishTemplatePayload,
  TemplateCategoryId,
  TemplateDetails,
  TemplateMetadata,
  TemplateToCategories,
  UpdateTemplatePayload,
} from "./types";
import { WorkspaceDetails } from "../workspaces/types";

export const fetchTemplateCategories = createAsyncAction(
  "FETCH_TEMPLATE_CATEGORIES_REQUEST",
  "FETCH_TEMPLATE_CATEGORIES_SUCCESS",
  "FETCH_TEMPLATE_CATEGORIES_FAILURE"
)<undefined, FetchTemplateCategoriesResponse, Error>();

export const fetchTemplates = createAsyncAction(
  "FETCH_TEMPLATES_REQUEST",
  "FETCH_TEMPLATES_SUCCESS",
  "FETCH_TEMPLATES_FAILURE"
)<TemplateCategoryId, FetchTemplatesResponse & TemplateCategoryId, Error>();

export const fetchOrganizationTemplates = createAsyncAction(
  "FETCH_ORGANIZATION_TEMPLATES_REQUEST",
  "FETCH_ORGANIZATION_TEMPLATES_SUCCESS",
  "FETCH_ORGANIZATION_TEMPLATES_FAILURE"
)<undefined, FetchOrganizationTemplatesResponse, Error>();

export const fetchTemplateDetails = createAsyncAction(
  "FETCH_TEMPLATE_DETAILS_REQUEST",
  "FETCH_TEMPLATE_DETAILS_SUCCESS",
  "FETCH_TEMPLATE_DETAILS_FAILURE"
)<string, TemplateDetails, Error>();

export const createTemplate = createAsyncAction(
  "CREATE_TEMPLATE_REQUEST",
  "CREATE_TEMPLATE_SUCCESS",
  "CREATE_TEMPLATE_FAILURE"
)<TemplateMetadata, TemplateMetadata, Error>();

export const deleteTemplate = createAsyncAction(
  "DELETE_TEMPLATE_REQUEST",
  "DELETE_TEMPLATE_SUCCESS",
  "DELETE_TEMPLATE_FAILURE"
)<WorkspaceDetails, string, Error>();

export const publishTemplate = createAsyncAction(
  "PUBLISH_TEMPLATE_REQUEST",
  "PUBLISH_TEMPLATE_SUCCESS",
  "PUBLISH_TEMPLATE_FAILURE"
)<PublishTemplatePayload, TemplateToCategories, Error>();

export const removeTemplateCategories = createAsyncAction(
  "REMOVE_TEMPLATE_CATEGORIES_REQUEST",
  "REMOVE_TEMPLATE_CATEGORIES_SUCCESS",
  "REMOVE_TEMPLATE_CATEGORIES_FAILURE"
)<TemplateToCategories, TemplateToCategories, Error>();

export const updateTemplateDetails = createAsyncAction(
  "UPDATE_TEMPLATE_DETAILS_REQUEST",
  "UPDATE_TEMPLATE_DETAILS_SUCCESS",
  "UPDATE_TEMPLATE_DETAILS_FAILURE"
)<UpdateTemplatePayload, TemplateMetadata, Error>();
