import { merge, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
  takeUntil,
  timeout,
} from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import {
  activateDashboardOption,
  applyTemplateToWorkspace,
  createWorkspaceFromTemplate,
  deleteWorkspace,
  duplicateWorkspace,
  editWorkspace,
  createWorkspaceFromTemplateId,
  getWorkspaceDetails,
  handleFullscreenChange,
  toggleWorkspaceVisibility,
  inviteToWorkspace,
  loadMoreWorkspaces,
  populateAllWorkspaces,
  populateInitialWorkspaces,
  queueCreateWorkspace,
  removeWorkspacePassword,
  setWorkspacePassword,
  updateWorkspaceDetails,
  updateWorkspaceLabels,
  updateWorkspaceName,
  uploadThumbnail as uploadThumbnailAction,
  removeThumbnail as removeThumbnailAction,
  workspaceSnapshot,
  upsertWorkspaceUsers,
  removeWorkspaceUsers,
  getMyTonnage,
  loadHiddenWorkspaces,
  leaveWorkspace,
  createWorkspace,
} from "../../state/workspaces/workspaces.actions";
import { fetchTemplateDetails } from "../../state/templates/templates.actions";
import type { AppEpic } from "../types";
import {
  waitForAppState,
  waitForConfigLoaded,
  waitForLicense,
  waitForToken,
} from "../helpers/waitForState";
import {
  getWorkspaceById,
  getWorkspaceFullscreen,
  getWorkspaceIsLive,
} from "../../state/workspaces/workspaces.selector";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import { Localized } from "../../strings";
import { AppMode } from "../../state/mode/mode.reducer";
import { log } from "@hoylu/client-common";
import { webexDetected } from "../../state/config/config.actions";
import {
  metadataV3toWorkspaceDetails,
  toWorkspaceDetails,
} from "./api/to.workspace.details";
import {
  authorizationHeader,
  userId,
  userToken,
} from "../../state/user/user.selector";
import { documentMetadata } from "../../state/config/config.selector";
import {
  UserInvitePayload,
  UserInviteResponse,
} from "../../state/user/user.actions";
import { sendMessageToWorkspace } from "../../post-message-dispatch";
import {
  DocumentMetadataV1Request,
  DocumentMetadataV1Response,
} from "./api/workspaces.v1.types";
import { defaultDashboardPageSize } from "../../utils/defaultDashboardPageSize";
import {
  informWorkspaceIfChangedToTemplate,
  updateDocumentTitle,
} from "../dependencies/workspaceDependencies";
import { DEFAULT_SCHEMA_VERSION } from "../../state/workspaces/workspaces.reducer";
import { CreateWorkspaceRequestMetadata } from "./api/workspaces.v3.types";
import { WorkspaceType } from "../../state/workspaces/WorkspaceType";
import { ErrorResponseMessages } from "../../state/error/error.reducer";
import { isAjaxError } from "../dependencies/ajaxRequests";
import { isErrorResponse } from "./types";
import {
  defaultWorkspaceName,
  getCreateBlankWorkspacePayload,
  getCreateFromTemplatePayload,
} from "../../utils/create.workspace.utils";

export const populateInitialWorkspacesEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(
      // we do not use isActionOf() here as it was causing problems in case of more complex filtering
      (action) =>
        action.type === "POPULATE_INITIAL_WORKSPACES_REQUEST" ||
        (state$.value.context.user.isReloggedUser === true
          ? state$.value.mode !== AppMode.EDITING &&
            state$.value.mode !== AppMode.LOADING_WORKSPACE &&
            action.type === "LOGIN_SUCCESS"
          : action.type === "LOGIN_SUCCESS")
    ),
    waitForToken(state$),
    waitForLicense(state$),
    switchMap(() => {
      return documentIdv3Requests
        .getMyWorkspacesPaged(
          documentMetadata(state$.value),
          userToken(state$.value),
          userId(state$.value),
          defaultDashboardPageSize
        )
        .pipe(
          mergeMap((pagedWorkspaces) => {
            return of(
              populateInitialWorkspaces.success(pagedWorkspaces),
              getMyTonnage.request() // The request for my tonnage must be after user-specific feature flags have been retrieved. Doing this after populating the workspaces is one way to do this.
            );
          }),
          catchError((error) => of(populateInitialWorkspaces.failure(error)))
        );
    })
  );

export const loadMoreWorkspacesEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(loadMoreWorkspaces.request)),
    waitForToken(state$),
    waitForLicense(state$),
    mergeMap((action) => {
      return documentIdv3Requests
        .getMyWorkspacesPaged(
          documentMetadata(state$.value),
          userToken(state$.value),
          userId(state$.value),
          defaultDashboardPageSize,
          action.payload.cursorOrOffset
        )
        .pipe(
          mergeMap((pagedWorkspaces) =>
            of(loadMoreWorkspaces.success(pagedWorkspaces))
          ),
          catchError((error) =>
            of(
              loadMoreWorkspaces.failure({
                requestAction: action,
                error,
                message: Localized.string("ERROR.SOMETHING_WENT_WRONG"),
              })
            )
          )
        );
    })
  );

export const populateAllWorkspacesEpic: AppEpic = (
  action$,
  state$,
  { documentIdv1Requests }
) =>
  action$.pipe(
    filter(isActionOf(populateAllWorkspaces.request)),
    waitForToken(state$),
    waitForLicense(state$),
    // exhaustMap - ignore all actions until the current one has completed
    exhaustMap((action) => {
      return documentIdv1Requests
        .getAllMyWorkspaces(
          documentMetadata(state$.value),
          userToken(state$.value)
        )
        .pipe(
          mergeMap((response) =>
            of(populateAllWorkspaces.success({ details: response.details }))
          ),
          catchError((error) =>
            of(
              populateAllWorkspaces.failure({
                error,
                requestAction: action,
                message: Localized.string("ERROR.SOMETHING_WENT_WRONG"),
              })
            )
          )
        );
    })
  );

export const loadHiddenWorkspacesEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(loadHiddenWorkspaces.request)),
    waitForToken(state$),
    waitForLicense(state$),
    mergeMap((action) =>
      documentIdv3Requests
        .getMyHiddenWorkspacesPaged(
          documentMetadata(state$.value),
          userToken(state$.value),
          userId(state$.value),
          defaultDashboardPageSize,
          action.payload.cursorOrOffset
        )
        .pipe(
          mergeMap((pagedWorkspaces) =>
            of(loadHiddenWorkspaces.success(pagedWorkspaces))
          ),
          catchError((error) => of(loadHiddenWorkspaces.failure(error)))
        )
    )
  );

export const workspaceDetailsEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(getWorkspaceDetails.request)),
    waitForToken(state$),
    waitForLicense(state$),
    mergeMap((action) =>
      documentIdv3Requests
        .getRawWorkspaceMetadata(
          documentMetadata(state$.value),
          userToken(state$.value),
          action.payload
        )
        .pipe(
          mergeMap((response) => {
            if (response.workspaceType === "DELETED") {
              return of(
                getWorkspaceDetails.failure({
                  error: new Error(Localized.string("ERROR.WORKSPACE_DELETED")),
                  workspaceId: action.payload,
                  requestAction: action,
                  message: Localized.string("ERROR.WORKSPACE_HAS_BEEN_DELETED"),
                })
              );
            }

            const workspaceDetails = documentIdv3Requests
              .getWorkspaceRoles(
                documentMetadata(state$.value),
                userToken(state$.value),
                response.workspaceId
              )
              .pipe(
                map((roles) =>
                  getWorkspaceDetails.success(
                    metadataV3toWorkspaceDetails(response, roles)
                  )
                )
              );

            // TODO => there is an epic below notifyWorkspaceOnDetailsUpdate, but changing workspace to template is not sending proper actions to activate it
            // bigger refactor of changing workspace to template is needed to create proper actions and epics handling it,
            // informWorkspaceIfChangedToTemplate was implemented as a low regression solution to fix fast an issue caused by opened workspaces not getting info about becoming template
            if (response.templateId) {
              informWorkspaceIfChangedToTemplate(
                response,
                state$.value.context.workspaces.existing || []
              );
            }

            if (
              response.templateId &&
              state$.value.context.workspaces.activeOption === "OPTIONS"
            ) {
              return merge(
                workspaceDetails,
                of(fetchTemplateDetails.request(response.templateId))
              );
            }

            return workspaceDetails;
          }),
          catchError((error) =>
            of(
              getWorkspaceDetails.failure({
                error,
                workspaceId: action.payload,
                requestAction: action,
                message: Localized.string("ERROR.VERIFY_WORKSPACE_ID"),
              })
            )
          )
        )
    )
  );

export const autoUpdateWorkspaceDetailsOnShareEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(activateDashboardOption)),
    filter(
      (a) =>
        [
          DashboardOption.SHARE,
          DashboardOption.INFO,
          DashboardOption.PERMISSIONS,
          DashboardOption.OPTIONS,
          DashboardOption.EXPANDED_SHARE,
        ].includes(a.payload.optionType) && !!a.payload.workspaceId
    ),
    map(
      (a) => getWorkspaceDetails.request(a.payload.workspaceId!) // cannot be undefined/null because of filter above
    )
  );

export const createWorkspaceEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(createWorkspace.request)),
    waitForToken(state$),
    waitForLicense(state$),
    throttleTime(500),
    mergeMap((action) => {
      // Set default payload values if not provided
      const completeActionPayload: CreateWorkspaceRequestMetadata = {
        ...action.payload,
        workspaceName: action.payload.workspaceName || defaultWorkspaceName,
        workspaceType: WorkspaceType.HOYLU,
        schemaVersion: DEFAULT_SCHEMA_VERSION,
        /* If we are on the Project view on the Dashboard - checking via store property "selectedProject"
           we want to add a newly created ws immediately to that Project by setting "containerId" */
        containerId: state$.value.context.workspaces.selectedProject?.id,
      };

      return documentIdv3Requests
        .createWorkspace(
          documentMetadata(state$.value),
          userToken(state$.value),
          completeActionPayload
        )
        .pipe(
          mergeMap((response) =>
            of(createWorkspace.success(metadataV3toWorkspaceDetails(response)))
          ),
          catchError((error) => {
            let message = Localized.string("ERROR.CANNOT_CREATE_WORKSPACE");

            if (state$.value.context.workspaces.selectedProject) {
              if (isAjaxError(error)) {
                console.dir(error);
                switch (error.status) {
                  case 402:
                    message = [
                      Localized.string("ERROR.PROJECTS.CANNOT_ADD_WORKSPACE"),
                      isErrorResponse(error.response)
                        ? error.response.code === "WorkspaceLimitReached"
                          ? Localized.string(
                              "ERROR.PROJECTS.REASON_MAX_WORKSPACES"
                            )
                          : error.response.message
                        : String(error.response),
                    ].join(" ");
                    break;
                  case 403:
                    message = Localized.string(
                      "ERROR.PROJECTS.INSUFFICIENT_PERMISSIONS_TO_ADD_WORKSPACE",
                      String(error.response)
                    );
                    break;
                }
              }
            }
            return of(
              createWorkspace.failure({
                requestAction: action,
                error,
                message,
              })
            );
          })
        );
    })
  );

export const duplicateWorkspaceEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(duplicateWorkspace.request)),
    waitForToken(state$),
    waitForLicense(state$),
    throttleTime(500),
    mergeMap((action) => {
      const {
        workspaceId,
        password,
        workspaceName,
        globalAccess,
        newPassword,
        open,
      } = action.payload;

      return documentIdv3Requests
        .duplicateWorkspace(
          documentMetadata(state$.value),
          userToken(state$.value),
          /* source workspace */
          workspaceId,
          password,
          /* new workspace */
          {
            workspaceName,
            globalAccess,
            password: newPassword || null,
            /* If we are on the Project view on the Dashboard - checking via store property "selectedProject"
               we want to add a newly created ws immediately to that Project by setting "containerId" */
            containerId: state$.value.context.workspaces.selectedProject?.id,
          }
        )
        .pipe(
          mergeMap((response) =>
            of(
              duplicateWorkspace.success({
                details: metadataV3toWorkspaceDetails(response),
                open,
              })
            )
          ),
          catchError((error) => {
            let failureAction;
            let message = Localized.string("ERROR.CANNOT_DUPLICATE_WORKSPACE");

            if (state$.value.context.workspaces.selectedProject) {
              if (isAjaxError(error)) {
                console.dir(error);
                switch (error.status) {
                  case 402:
                    message = [
                      Localized.string("ERROR.PROJECTS.CANNOT_ADD_WORKSPACE"),
                      isErrorResponse(error.response)
                        ? error.response.code === "WorkspaceLimitReached"
                          ? Localized.string(
                              "ERROR.PROJECTS.REASON_MAX_WORKSPACES"
                            )
                          : error.response.message
                        : String(error.response),
                    ].join(" ");
                    break;
                  case 403:
                    message = Localized.string(
                      "ERROR.PROJECTS.INSUFFICIENT_PERMISSIONS_TO_ADD_WORKSPACE",
                      String(error.response)
                    );
                    break;
                }
              }
            }
            const passwordError =
              error?.response?.code === ErrorResponseMessages.InvalidPassword;

            if (passwordError) {
              message = Localized.string("ERROR.INCORRECT_PASSWORD");
            }

            failureAction = duplicateWorkspace.failure({
              requestAction: action,
              error,
              clearError: !passwordError, // Want to keep the duplicate dialog after user clicks OK if incorrect password
              message: message,
            });
            return of(failureAction);
          })
        );
    })
  );

export const openWorkspaceAfterDuplicateIfRequestedEpic: AppEpic = (
  action$,
  state$,
  { getCurrentDate }
) =>
  action$.pipe(
    filter(isActionOf(duplicateWorkspace.success)),
    filter((a) => !!a.payload.open),
    mergeMap((a) =>
      of(
        editWorkspace({
          workspaceId: a.payload.details.workspaceId,
          lastAccess: getCurrentDate(),
        })
      )
    )
  );

export const queueCreateWorkspaceEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(queueCreateWorkspace)),
    waitForToken(state$),
    waitForLicense(state$),
    mergeMap(() => {
      return of(createWorkspace.request(getCreateBlankWorkspacePayload()));
    })
  );

export const upsertWorkspaceUsersEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) => {
  return action$.pipe(
    filter(isActionOf(upsertWorkspaceUsers.request)),
    waitForToken(state$),
    mergeMap((action) => {
      return documentIdv3Requests
        .upsertWorkspaceUserPermissions(
          documentMetadata(state$.value),
          userToken(state$.value),
          action.payload.workspaceId,
          action.payload.userEmails,
          action.payload.permission
        )
        .pipe(
          mergeMap((response) =>
            of(
              upsertWorkspaceUsers.success(action.payload),
              // also trigger updateWorkspaceDetails since this will update the workspace details in the state
              updateWorkspaceDetails.success(response)
            )
          ),
          catchError((error) =>
            of(
              upsertWorkspaceUsers.failure({
                requestAction: action,
                error,
                message: Localized.string("ERROR.CANNOT_APPLY_CHANGES"),
              })
            )
          )
        );
    })
  );
};

export const removeWorkspaceUserEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) => {
  return action$.pipe(
    filter(isActionOf(removeWorkspaceUsers.request)),
    waitForToken(state$),
    mergeMap((action) => {
      return documentIdv3Requests
        .removeUsersFromWorkspacePermissions(
          documentMetadata(state$.value),
          userToken(state$.value),
          action.payload.workspaceId,
          action.payload.userEmails
        )
        .pipe(
          mergeMap((response) =>
            of(
              removeWorkspaceUsers.success(action.payload),
              // also trigger updateWorkspaceDetails since this will update the workspace details in the state
              updateWorkspaceDetails.success(response)
            )
          ),
          catchError((error) =>
            of(
              removeWorkspaceUsers.failure({
                requestAction: action,
                error,
                message: Localized.string("ERROR.CANNOT_APPLY_CHANGES"),
              })
            )
          )
        );
    })
  );
};

// TODO: Replace with actions specific to certain workspace properties
export const updateWorkspaceDetailsEpic: AppEpic = (
  action$,
  state$,
  { putJSON }
) => {
  return action$.pipe(
    filter(isActionOf(updateWorkspaceDetails.request)),
    waitForToken(state$),
    mergeMap((action) => {
      let workspaceDetails = getWorkspaceById(
        state$.value,
        action.payload.workspaceId
      );
      return putJSON<DocumentMetadataV1Request, DocumentMetadataV1Response>(
        `${documentMetadata(state$.value)}/api/v1/${
          action.payload.workspaceId
        }`,
        {
          schemaVersion: workspaceDetails?.schemaVersion,
          // only name and permissions are suppored to be updated for now
          documentType: workspaceDetails?.workspaceType,
          pageSize: workspaceDetails?.pageSize,
          documentName:
            (action.payload.workspaceName ?? workspaceDetails?.workspaceName) ||
            "",
          module: workspaceDetails?.module || action.payload.module,
          permissions: action.payload.roles,
          // Remark: If document metadata change, new properties need to be added here too otherwise changing a workspace name will delete them
          //         Once my-workspace service is fixed, we could simplify this code again
        },
        {
          Authorization: `Bearer ${state$.value.context.user.token}`,
        }
      ).pipe(
        mergeMap((response) =>
          of(updateWorkspaceDetails.success(toWorkspaceDetails(response)))
        ),
        catchError((error) =>
          of(
            // Would be nice to differentiate error based on if user was trying to update permissions vs rename etc
            updateWorkspaceDetails.failure({
              requestAction: action,
              error,
              message: Localized.string("ERROR.CANNOT_APPLY_CHANGES"),
            })
          )
        )
      );
    })
  );
};

export const updateWorkspaceLabelsEpic: AppEpic = (
  action$,
  state$,
  { documentIdv2Requests }
) => {
  return action$.pipe(
    filter(isActionOf(updateWorkspaceLabels.request)),
    waitForToken(state$),
    mergeMap((action) =>
      documentIdv2Requests
        .updateWorkspaceLabels(
          documentMetadata(state$.value),
          userToken(state$.value),
          action.payload.workspaceId,
          action.payload.labels
        )
        .pipe(
          mergeMap((response) =>
            of(
              updateWorkspaceLabels.success({
                labels: response,
                workspaceId: action.payload.workspaceId,
              })
            )
          ),
          catchError((error) =>
            of(
              updateWorkspaceLabels.failure({
                requestAction: action,
                error,
                message: Localized.string("ERROR.CANNOT_APPLY_CHANGES"),
              })
            )
          )
        )
    )
  );
};

export const autoEditCreatedWorkspaceEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(isActionOf([createWorkspace.success])),
    map((a) =>
      editWorkspace({
        workspaceId: a.payload.workspaceId,
        lastAccess: new Date(),
      })
    )
  );

export const webexPinWorkspaceOnEditEpic: AppEpic = (
  actions$,
  state$,
  { setWebexShareUrl }
) =>
  actions$.pipe(
    filter(isActionOf(editWorkspace)),
    filter(() => !!state$.value.context.config.webexMode?.isInSelectorMode),
    tap(async (a) => {
      try {
        await setWebexShareUrl(
          a.payload.workspaceId,
          state$.value.context.workspaces.waitingToEditName || "Hoylu Workspace"
        );
      } catch (e) {
        log.error("webexPinWorkspaceOnEditEpic: error:", { exception: e });
      }
    }),
    ignoreElements()
  );

export const applyTemplateToCreatedWorkspaceEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(createWorkspace.success)),
    filter((a) => a.payload.module.name !== undefined),
    mergeMap((a) => {
      return of(a).pipe(
        waitForAppState(state$, (s) => {
          return (
            getWorkspaceById(s, a.payload.workspaceId)?.isDocumentReady === true
          );
        })
      );
    }),
    map((a) =>
      applyTemplateToWorkspace({
        workspaceId: a.payload.workspaceId,
        templateName: a.payload.module.name,
      })
    )
  );

export const webexDetectedEpic: AppEpic = (
  action$,
  state$,
  { setWebexShareUrl }
) =>
  action$.pipe(
    filter(isActionOf(webexDetected)),
    filter(
      () =>
        state$.value.mode === AppMode.LOADING_WORKSPACE ||
        state$.value.mode === AppMode.EDITING
    ), // don't allow sharing Dashboard
    tap(async (a) => {
      // only call setWebexShareUrl when app is in "select workspace mode" (popup in spaces or add app flow in meetings)
      // because webex SDK seems to hang up if it is called in an already pinned or shared workspace
      if (!a.payload.isInSelectorMode) return;
      const fullscreenWorkspace = getWorkspaceFullscreen(state$.value);
      if (!fullscreenWorkspace) return;
      try {
        await setWebexShareUrl(
          fullscreenWorkspace.workspaceId,
          fullscreenWorkspace.workspaceName || "Hoylu Workspace"
        );
      } catch (e) {
        log.error("webexDetectedEpic: error:", { exception: e });
      }
    }),
    ignoreElements()
  );

export const postApplyTemplateToWorkspaceEpic: AppEpic = (
  action$,
  _,
  { sendMessageToWorkspace }
) =>
  action$.pipe(
    filter(isActionOf(applyTemplateToWorkspace)),
    tap((a) =>
      sendMessageToWorkspace(a.payload.workspaceId, {
        action: "APPLY_TEMPLATE",
        templateName: a.payload.templateName,
      })
    ),
    ignoreElements()
  );

export const postHandleFullscreenEpic: AppEpic = (
  action$,
  state$,
  { sendMessageToWorkspace }
) =>
  action$.pipe(
    filter(isActionOf(handleFullscreenChange)),
    tap(() => {
      let liveWorkspaces = getWorkspaceIsLive(state$.value);
      liveWorkspaces.forEach((workspace) => {
        sendMessageToWorkspace(workspace.workspaceId, {
          action: "TOGGLE_FULLSCREEN",
        });
      });
    }),
    ignoreElements()
  );

export const notifyWorkspaceOnDetailsUpdate: AppEpic = (
  action$,
  state$,
  { sendMessageToWorkspace }
) =>
  action$.pipe(
    filter(isActionOf(updateWorkspaceDetails.success)),
    tap((a) =>
      sendMessageToWorkspace(a.payload.workspaceId, {
        action: "WORKSPACE_DETAILS_UPDATED",
        details: {
          workspaceName: a.payload.workspaceName,
        },
      })
    ),
    ignoreElements()
  );

export const toggleWorkspaceVisibilityEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(toggleWorkspaceVisibility.request)),
    waitForToken(state$),
    mergeMap((a) => {
      return documentIdv3Requests
        .setWorkspaceVisibility(
          documentMetadata(state$.value),
          userToken(state$.value),
          userId(state$.value),
          a.payload.workspaceId,
          !a.payload.isHidden // isHidden
        )
        .pipe(
          map(() => toggleWorkspaceVisibility.success(a.payload)),
          catchError((error) => {
            return of(
              toggleWorkspaceVisibility.failure({
                error,
                requestAction: a,
                message: Localized.string(
                  `ERROR.CANNOT_${
                    a.payload.isHidden ? "UNHIDE" : "HIDE"
                  }_WORKSPACE`
                ),
              })
            );
          })
        );
    })
  );

export const leaveWorkspaceEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests }
) =>
  action$.pipe(
    filter(isActionOf(leaveWorkspace.request)),
    waitForToken(state$),
    mergeMap((a) =>
      documentIdv3Requests
        .removeFromMyWorkspaces(
          documentMetadata(state$.value),
          userToken(state$.value),
          userId(state$.value),
          a.payload.workspaceId
        )
        .pipe(
          map(() => leaveWorkspace.success()),
          catchError((error) =>
            of(
              leaveWorkspace.failure({
                error,
                requestAction: a,
                message: Localized.string("ERROR.CANNOT_DELETE_WORKSPACE"),
              })
            )
          )
        )
    )
  );

export const deleteWorkspaceEpic: AppEpic = (
  action$,
  state$,
  { documentIdv1Requests }
) =>
  action$.pipe(
    filter(isActionOf(deleteWorkspace.request)),
    waitForToken(state$),
    mergeMap((a) =>
      documentIdv1Requests
        .deleteWorkspace(
          documentMetadata(state$.value),
          userToken(state$.value),
          a.payload.details.workspaceId,
          a.payload.password
        )
        .pipe(
          map(() => {
            return deleteWorkspace.success();
          }),
          catchError((error) =>
            of(
              deleteWorkspace.failure({
                error,
                requestAction: a,
                message: Localized.string("ERROR.CANNOT_DELETE_WORKSPACE"),
              })
            )
          )
        )
    )
  );

export const setWorkspacePasswordEpic: AppEpic = (
  action$,
  state$,
  { documentIdv1Requests }
) =>
  action$.pipe(
    filter(isActionOf(setWorkspacePassword.request)),
    waitForToken(state$),
    mergeMap((a) => {
      return documentIdv1Requests
        .setWorkspacePassword(
          documentMetadata(state$.value),
          userToken(state$.value),
          a.payload.workspaceId,
          a.payload.currPassword,
          a.payload.newPassword
        )
        .pipe(
          mergeMap(() => merge(of(setWorkspacePassword.success(a.payload)))),
          catchError((error) => {
            let message =
              error.status === 403
                ? Localized.string("ERROR.INCORRECT_WORKSPACE_PASSWORD")
                : Localized.string("ERROR.CANNOT_SET_PASSWORD");
            return of(
              setWorkspacePassword.failure({
                error: error,
                message: message,
                requestAction: a,
              })
            );
          })
        );
    })
  );

export const removeWorkspacePasswordEpic: AppEpic = (
  action$,
  state$,
  { documentIdv1Requests }
) =>
  action$.pipe(
    filter(isActionOf(removeWorkspacePassword.request)),
    waitForToken(state$),
    mergeMap((a) => {
      return documentIdv1Requests
        .removeWorkspacePassword(
          documentMetadata(state$.value),
          userToken(state$.value),
          a.payload.workspaceId,
          a.payload.currPassword
        )
        .pipe(
          mergeMap(() => merge(of(removeWorkspacePassword.success(a.payload)))),
          catchError((error) => {
            let message =
              error.status === 403
                ? Localized.string("ERROR.INCORRECT_WORKSPACE_PASSWORD")
                : Localized.string("ERROR.CANNOT_REMOVE_PASSWORD");
            return of(
              removeWorkspacePassword.failure({
                error: error,
                message: message,
                requestAction: a,
              })
            );
          })
        );
    })
  );

export const createWorkspaceFromTemplateEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(createWorkspaceFromTemplate)),
    mergeMap((action) => {
      const isPasswordRequired = state$.value.context.user.requiresPassword;
      const { workspaceId, hasPassword } = action.payload;

      if (hasPassword || isPasswordRequired) {
        return of(
          activateDashboardOption({
            optionType: DashboardOption.CREATE_FROM_TEMPLATE,
          })
        );
      }

      return of(
        duplicateWorkspace.request(getCreateFromTemplatePayload(workspaceId))
      );
    })
  );

export const inviteToWorkspaceEpic: AppEpic = (action$, state$, { postJSON }) =>
  action$.pipe(
    filter(isActionOf(inviteToWorkspace.request)),
    waitForToken(state$),
    waitForConfigLoaded(state$),
    mergeMap(({ payload }) => {
      // TODO: extract postJSON to a request file for auth service like we do with documentId service
      return postJSON<UserInvitePayload, UserInviteResponse>(
        `${state$.value.context.config.serviceConfig.auth}/invite`,
        payload.invite,
        authorizationHeader(state$.value)
      ).pipe(
        mergeMap((response) => {
          const invitesSent = Object.entries(response)
            .filter(([, result]) => result.startsWith("200"))
            .map(([email]) => email);
          if (invitesSent.length === 0)
            return of(inviteToWorkspace.failure(Error("No invites sent")));
          return of(
            upsertWorkspaceUsers.request({
              workspaceId: payload.workspaceId,
              permission: payload.newUsersPermission,
              userEmails: invitesSent,
            })
          );
        }),
        catchError((error) => {
          console.warn("Unable to invite users:", payload, error);
          //For now we don't really care about the error handling here
          return of(inviteToWorkspace.failure(error));
        })
      );
    })
  );

export const updateWorkspaceNameEpic: AppEpic = (
  action$,
  state$,
  { documentIdv1Requests }
) =>
  action$.pipe(
    filter(isActionOf(updateWorkspaceName.request)),
    mergeMap((action) =>
      documentIdv1Requests
        .updateWorkspaceName(
          documentMetadata(state$.value),
          userToken(state$.value),
          action.payload.workspaceId,
          action.payload.name
        )
        .pipe(
          // TODO: Eventually we want might want to use updateWorkspaceName.success instead
          //       but before that we need to understand what the dependencies of updateWorkspaceDetails.success are.
          mergeMap((w) => {
            // Keep "Hoylu" Browser's tab name while being on Dashboard
            if (state$.value.mode === AppMode.EDITING) {
              updateDocumentTitle(action.payload.name);
            }

            return of(updateWorkspaceDetails.success(w));
          }),
          catchError((error) =>
            of(
              updateWorkspaceName.failure({
                error,
                requestAction: action,
                message: "Failed to update workspace name",
              })
            )
          )
        )
    )
  );

export const createWorkspaceFromTemplateIdEpic: AppEpic = (
  action$,
  state$,
  { documentIdv3Requests, templateRequests }
) =>
  action$.pipe(
    filter(isActionOf(createWorkspaceFromTemplateId.request)),
    mergeMap((action) => {
      return templateRequests
        .getTemplateById(
          documentMetadata(state$.value),
          userToken(state$.value),
          action.payload.templateId
        )
        .pipe(
          mergeMap(({ workspaceId }) => {
            return documentIdv3Requests.getRawWorkspaceMetadata(
              documentMetadata(state$.value),
              userToken(state$.value),
              workspaceId
            );
          }),
          mergeMap((response) => {
            return of(
              createWorkspaceFromTemplate(
                metadataV3toWorkspaceDetails(response)
              )
            );
          }),
          catchError((error) => {
            return of(
              createWorkspaceFromTemplateId.failure({
                error,
                requestAction: action,
                message: "Failed to get template",
              })
            );
          })
        );
    })
  );

export const updateThumbnailEpic: AppEpic = (
  action$,
  state$,
  { uploadThumbnail }
) =>
  action$.pipe(
    filter(isActionOf(uploadThumbnailAction.request)),
    waitForToken(state$),
    waitForConfigLoaded(state$),
    mergeMap((action) => {
      return uploadThumbnail(
        state$.value,
        action.payload.workspaceId,
        action.payload.file
      ).pipe(
        mergeMap((r) => {
          const details = getWorkspaceById(
            state$.value,
            action.payload.workspaceId
          )!;
          return of(
            updateWorkspaceDetails.success({
              ...details,
              thumbnailUrl: r,
              thumbnailChanged: !details.thumbnailChanged,
            }),
            uploadThumbnailAction.success({
              workspaceId: action.payload.workspaceId,
              url: r,
            })
          );
        }),
        catchError((error) =>
          //: Since the uploadService does not use RxJS ajax we cannot use the retryable Actions behavior to refresh tokens on 403. Its a limitation of the current implementation.
          of(uploadThumbnailAction.failure(error))
        )
      );
    })
  );

export const removeThumbnailEpic: AppEpic = (
  action$,
  state$,
  { removeThumbnail }
) =>
  action$.pipe(
    filter(isActionOf(removeThumbnailAction.request)),
    waitForToken(state$),
    waitForConfigLoaded(state$),
    mergeMap((action) => {
      return removeThumbnail(state$.value, action.payload.workspaceId).pipe(
        mergeMap(() => {
          const details = getWorkspaceById(
            state$.value,
            action.payload.workspaceId
          )!;
          return of(
            updateWorkspaceDetails.success({
              ...details,
              thumbnailUrl: undefined,
              thumbnailChanged: !details.thumbnailChanged,
            }),
            removeThumbnailAction.success({
              workspaceId: action.payload.workspaceId,
            })
          );
        }),
        catchError((error) => of(removeThumbnailAction.failure(error)))
      );
    })
  );

export const snapshotEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(workspaceSnapshot.request)),
    mergeMap((action) => {
      sendMessageToWorkspace(action.payload.workspaceId, {
        action: "TAKE_SNAPSHOT",
      });
      return action$.pipe(
        filter(
          isActionOf(workspaceSnapshot.success) ||
            isActionOf(workspaceSnapshot.failure)
        ),
        takeUntil(action$.pipe(filter(isActionOf(workspaceSnapshot.failure)))),
        takeUntil(action$.pipe(filter(isActionOf(workspaceSnapshot.success)))),
        timeout(30000),
        catchError(() =>
          of(
            workspaceSnapshot.failure(
              new Error("Snapshot service is not responding in time")
            )
          )
        )
      );
    })
  );
