import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { getAllWorkspaces } from "../../state/workspaces/workspaces.selector";
import { defaultDashboardPageSize } from "../../utils/defaultDashboardPageSize";
import { loadMoreWorkspaces } from "../../state/workspaces/workspaces.actions";
import Styles from "./WorkspaceLayout.module.css";
import { APP_NODE_ID } from "../../app.defaults";

export const moreWorkspacesToLoad = (
  hasPopulatedAllWorkspaces: boolean,
  cursorOrOffset: string | number
): boolean => {
  if (hasPopulatedAllWorkspaces || cursorOrOffset === "") {
    return false;
  }
  return true;
};

export const WorkspacesLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const hasPopulatedAllWorkspaces = useSelector(
    (state: RootState) => state.context.workspaces.hasPopulatedAllWorkspaces
  );
  const cursor = useSelector(
    (state: RootState) => state.context.workspaces.cursorOrOffset
  );
  const workspaces = useSelector((state: RootState) => getAllWorkspaces(state));
  const dispatch = useDispatch();

  return (
    <>
      <InfiniteScroll
        dataLength={workspaces.length}
        next={() =>
          dispatch(
            loadMoreWorkspaces.request({
              limit: defaultDashboardPageSize,
              cursorOrOffset: cursor,
            })
          )
        }
        hasMore={moreWorkspacesToLoad(hasPopulatedAllWorkspaces, cursor)}
        loader={""}
        className={Styles.infiniteScroll}
        scrollableTarget={APP_NODE_ID}
      >
        {React.Children.map(
          children,
          (child) =>
            React.isValidElement(child) && (
              <div
                key={child.key}
                style={
                  Number.isInteger(child.props.order)
                    ? { order: child.props.order }
                    : {}
                }
                className={`${child.props.hide ? Styles.hidden : ""} ${
                  Styles.card
                }`}
              >
                {child}
              </div>
            )
        )}
      </InfiniteScroll>
    </>
  );
};
