import React, { useMemo } from "react";
import { FrameContexts } from "@microsoft/teams-js";
import Styles from "./live.workspace.module.css";
import { WorkspaceDetails } from "../state/workspaces/types";
import { useSelector } from "react-redux";
import { config } from "../state/config/config.selector";

type LiveWorkspaceProps = {
  workspace: WorkspaceDetails;
};

type WorkspaceIframeProps = {
  workspaceId: string;
  workspaceUrl: string | undefined;
  allowFullScreen: boolean | undefined;
};

export const LiveWorkspace = ({ workspace }: LiveWorkspaceProps) => {
  const configuration = useSelector(config);
  const { workspaceId, workspaceUrl, isFullscreen } = workspace;
  const memoizedPropsForIframe: WorkspaceIframeProps = useMemo(
    () => ({
      workspaceId,
      workspaceUrl,
      allowFullScreen:
        workspace.isFullscreen &&
        configuration.teamsFrame !== FrameContexts.sidePanel,
    }),
    [workspaceId, workspaceUrl, isFullscreen, configuration.teamsFrame]
  );

  return <WorkspaceIframe {...memoizedPropsForIframe} />;
};

const WorkspaceIframe = ({
  workspaceId,
  workspaceUrl,
  allowFullScreen,
}: WorkspaceIframeProps) => (
  <iframe
    name="iframes"
    id={workspaceId}
    title={workspaceId}
    className={`${Styles.liveWorkspacePreview} ${
      allowFullScreen ? Styles.fullScreen : Styles.hiddenIframe
    }`}
    src={workspaceUrl}
  />
);
